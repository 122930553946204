import {Button, FormControl, FormLabel, HStack, Image, Input, Select, Spinner, Text, VStack} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import "../../App.css";
import axios, {AxiosResponse} from 'axios';
import {baseUrl, checkIsUserBlocked} from "../../Course";
import {InitData} from "../webapp/model";

interface ExchangeRequest {
    userId: number,
    currencyFrom: number;
    currencyTo: number;
    sumFrom: number;
    typeOfDelivery: number;
    fromTo: boolean;
    bank: string;
}

interface ExchangeData {
    sumTo: number;
    sumFrom: number;
    info: string;
    percent: number;
    exchangeRate: number;
    profit: number;
    course: string;
    filePath: string
}

interface UserId {
    userId: number;
}

const THB = 0
const RUB = 1
const USDT = 2
const USD = 3

function currencyName(currencyCode: number): string {
    switch (currencyCode) {
        case 0:
            return "THB";
        case 1:
            return "RUB";
        case 2:
            return "USDT";
        case 3:
            return "USD";
        default:
            return "ERROR";
    }
}

function currencyRusName(currencyCode: number): string {
    switch (currencyCode) {
        case 0:
            return "БАТЫ";
        case 1:
            return "РУБЛИ";
        case 2:
            return "USDT";
        case 3:
            return "ДОЛЛАРЫ";
        default:
            return "ERROR";
    }
}

function deliveryTypeName(dt: number): string {
    switch (dt) {
        case 0:
            return "Доставка";
        case 1:
            return "Личная встреча";
        case 3:
            return "Банкомат";
        case 6:
            return "Перевод на счёт или оплата по QR";
        case 7:
            return "Перевод на кошелёк";
        case 8:
            return "Внесение денег в банкомат";
        case 10:
            return "Офис";
        default:
            return "Error";
    }
}

function getDeliveryTypes(currencyFrom: number, currencyTo: number): number[] {
    if (currencyFrom === RUB && currencyTo === THB) {
        return [3, 0, 6];
    } else if (currencyFrom === RUB && currencyTo === USDT) {
        return [10, 7];
    } else if (currencyTo === USD) {
        return [0, 10];
    } else if (currencyFrom === USDT && currencyTo === THB) {
        return [0, 3, 6];
    } else if (currencyFrom === USDT && currencyTo === RUB) {
        return [6, 10];
    } else if (currencyFrom === USD && currencyTo === THB) {
        return [3, 0, 6];
    } else if (currencyFrom === USD && currencyTo === RUB) {
        return [10];
    } else if (currencyFrom === USD && currencyTo === USDT) {
        return [10];
    } else if (currencyFrom === THB && currencyTo === USDT) {
        return [10, 7];
    } else if (currencyFrom === THB && currencyTo === RUB) {
        return [6, 10];
    } else {
        return [3, 0, 6];
    }
}

function strToBoolean(str: string) {
    return str === "true";
}

function bankName(bank: string): string {
    switch (bank) {
        case "tinkoff":
            return "Тинькофф";
        case "sber":
            return "СБЕР";
        case "raiffeisen":
            return "Райффайзен";
        default:
            return "Другой";
    }
}

function exchangeRate(
    sum: ExchangeData,
    currencyTo: number,
    currencyFrom: number
): number {
    switch (true) {
        case currencyTo === THB && currencyFrom === RUB:
            return sum.exchangeRate * (1 + sum.percent / 100);
        case currencyTo === THB && currencyFrom === USDT:
            return sum.exchangeRate * (1 - sum.percent / 100);
        case currencyTo === THB && currencyFrom === USD:
            return sum.exchangeRate * (1 - sum.percent / 100);
        case currencyTo === RUB && currencyFrom === THB:
            return sum.exchangeRate * (1 - sum.percent / 100);
        case currencyTo === RUB && currencyFrom === USDT:
            return sum.exchangeRate * (1 - sum.percent / 100);
        case currencyTo === RUB && currencyFrom === USD:
            return sum.exchangeRate * (1 - sum.percent / 100);
        case currencyTo === USDT && currencyFrom === THB:
            return sum.exchangeRate * (1 + sum.percent / 100);
        case currencyTo === USDT && currencyFrom === RUB:
            return sum.exchangeRate * (1 + sum.percent / 100);
        case currencyTo === USDT && currencyFrom === USD:
            return sum.exchangeRate * (1 + sum.percent / 100);
        case currencyTo === USD && currencyFrom === THB:
            return sum.exchangeRate * (1 + sum.percent / 100);
        case currencyTo === USD && currencyFrom === RUB:
            return sum.exchangeRate * (1 + sum.percent / 100);
        case currencyTo === USD && currencyFrom === USDT:
            return sum.exchangeRate * (1 + sum.percent / 100);
        default:
            return 0;
    }
}

const admins = [538321015, 214058394]

const allCurrencyToList = [THB, RUB, USD, USDT]
export const tg = window.Telegram.WebApp;

const Calculator = () => {
    const [currencyFrom, setCurrencyFrom] = useState<number | null>(null)
    const [currencyTo, setCurrencyTo] = useState<number | null>(null)
    const [currencyToList, setCurrencyToList] = useState<number[] | null>(null)
    const [deliveryTypes, setDeliveryTypes] = useState<number[]>([])
    const [deliveryType, setDeliveryType] = useState<number | null>(null)
    const [currencyFromName, setCurrencyFromName] = useState("")
    const [currencyToName, setCurrencyToName] = useState("")
    const [curName, setCurName] = useState("")
    const [fromTo, setFromTo] = useState<boolean | undefined>(undefined)
    const [bank, setBank] = useState<string | null>(null)
    const [sum, setSum] = useState(0)
    const [counted, setCounted] = useState(false)
    const [exchangeData, setExchangeData] = useState<ExchangeData | null>(null)
    const [exchangeRequest, setExchangeRequest] = useState<ExchangeRequest | null>(null)
    const [initData, setInitData] = useState<InitData | null>(null)
    const [countButtonColor, setCountButtonColor] = useState("#434343")
    const [sendButtonColor, setSendButtonColor] = useState("rgba(183, 219, 0, 0.8)")
    const [isLoading, setIsLoading] = useState(false)
    const inputRef = useRef(null);
    const [isBlocked, setIsBlocked] = useState<boolean>(true)

    useEffect(() => {
        tg.expand()

        if (tg.initData !== "") {
            const queryParams = new URLSearchParams(tg.initData);
            const queryData: InitData = {
                query_id: queryParams.get('query_id') || '',
                user: JSON.parse(queryParams.get('user') || '{}'),
                auth_date: parseInt(queryParams.get('auth_date') || '0', 10),
                hash: queryParams.get('hash') || ''
            };
            console.log(queryData)
            setInitData(queryData)
            tg.enableClosingConfirmation()
            //tg.requestWriteAccess()
        }
        tg.ready()
        
        try {
            tg.disableVerticalSwipes()
        } catch (e) {
            console.error(e)
        }
    }, []);

    useEffect(() => {
        if (initData) {
            checkIsUserBlocked(initData.user.id)
                .then(r => setIsBlocked(r))
                .catch(e => {
                    console.log(e)
                })
        }
    }, [initData]);

    const popupButtons: PopupButton[] = [
        {
            id: "1",
            type: "destructive",
            text: "Закрыть"
        },
        {
            id: "0",
            type: "default",
            text: "Отмена"
        }
    ]

    const popupParams: PopupParams = {
        title: "Закрыть приложение?",
        message: "Закрыть приложение?",
        buttons: popupButtons
    }


    useEffect(() => {
        if (currencyFrom !== null) {
            setCurrencyFromName(currencyName(currencyFrom))
        }
        if (currencyTo !== null) {
            setCurrencyToName(currencyName(currencyTo))
        }
    }, [currencyFrom, currencyTo]);

    useEffect(() => {
        if (currencyFrom !== null && currencyTo !== null) {
            setDeliveryTypes(getDeliveryTypes(currencyFrom, currencyTo))
        } else {
            setDeliveryTypes([])
        }
    }, [currencyFrom, currencyTo]);

    useEffect(() => {
        if (currencyFrom !== null && currencyTo !== null && deliveryType !== null) {
            setDeliveryType(deliveryTypes[0])
        } else {
            setDeliveryType(null)
        }
    }, [deliveryTypes]);

    /*useEffect(() => {
        console.log(deliveryType)
    }, [deliveryType]);*/

    useEffect(() => {
        if (currencyFrom !== 1) {
            setBank(null)
        }
        if (currencyFrom !== null) {
            let newCurrencyToList = allCurrencyToList.filter(cur => cur !== currencyFrom)
            if (currencyFrom === USD && admins.find(id => id === initData?.user.id) === undefined) {
                newCurrencyToList = newCurrencyToList.filter(cur => cur !== THB)
            } else if (currencyFrom === THB && admins.find(id => id === initData?.user.id) === undefined) {
                newCurrencyToList = newCurrencyToList.filter(cur => cur !== USD)
            }
            setCurrencyToList(newCurrencyToList)
        }
    }, [currencyFrom]);

    useEffect(() => {
        if (currencyTo !== null && currencyToList !== null && !currencyToList?.includes(currencyTo)) {
            setCurrencyTo(null)
        }
    }, [currencyToList]);

    useEffect(() => {
        if (fromTo && currencyFrom !== null) {
            setCurName(currencyName(currencyFrom))
        } else if (currencyTo !== null) {
            setCurName(currencyName(currencyTo))
        }
    }, [currencyFrom, currencyTo, fromTo]);

    async function makeExchangeRequest(data: ExchangeRequest): Promise<ExchangeData> {
        try {
            const response: AxiosResponse<ExchangeData> = await axios.post(`${baseUrl}/exchange`, data);
            return response.data;
        } catch (error) {
            console.error('Error making exchange request:', error);
            throw error;
        }
    }

    async function makeRequest(userId: number) {
        try {
            const UserId: UserId = {
                userId: userId
            }
            const response = await axios.post(`${baseUrl}/send_request`, UserId);
            return response.status;
        } catch (error) {
            console.error('Error making exchange request:', error);
            throw error;
        }
    }

    async function cancelRequest(userId: number) {
        try {
            const UserId: UserId = {
                userId: userId
            }
            const response = await axios.post(`${baseUrl}/cancel_request`, UserId);
            return response.status;
        } catch (error) {
            console.error('Error making exchange request:', error);
            throw error;
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            try {
                // @ts-ignore
                inputRef.current?.blur();
            } catch (e) {
                console.error('e');
            }
            count();
        }
    };

    useEffect(() => {
        console.log(exchangeData)
    }, [exchangeData]);

    const count = () => {
        if (initData?.user.id && sum !== 0 && currencyFrom !== null && currencyTo !== null && deliveryType !== null && !(currencyFrom === 1 && bank === null) && fromTo !== undefined) {
            setIsLoading(true)
            const exchangeRequest: ExchangeRequest = {
                userId: initData?.user.id,
                currencyFrom: currencyFrom,
                currencyTo: currencyTo,
                sumFrom: sum,
                typeOfDelivery: deliveryType,
                fromTo: fromTo,
                bank: bank || "",
            };

            setExchangeRequest(exchangeRequest)

            makeExchangeRequest(exchangeRequest)
                .then((exchangeData) => {
                    //console.log('Exchange result:', exchangeData);
                    setExchangeData(exchangeData);
                    setCounted(true);
                })
                .catch((error) => {
                    tg.showAlert("Не удалось выполнить запрос! Используйте калькулятор в самом боте.")
                    console.error('Error:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            //console.log(`sum: ${sum} currencyFrom: ${currencyFrom} currencyTo: ${currencyTo} deliveryType: ${deliveryType} bank: ${bank}`);
            if (sum === 0) {
                tg.showAlert("Введите сумму!")
            } else if (currencyFrom === null) {
                tg.showAlert("Выберите валюту, которую отдаёте!")
            } else if (currencyTo === null) {
                tg.showAlert("Выберите валюту, которую хотите получить!")
            } else if (deliveryType === null) {
                tg.showAlert("Выберите способ выдачи!")
            } else if (currencyFrom === 1 && bank === null) {
                tg.showAlert("Выберите способ оплаты!")
            } else if (fromTo === undefined) {
                tg.showAlert("Выберите способ обмена!")
            }
        }
    }

    const sendRequest = () => {
        //tg.showConfirm("Отправить заявку? Для этого нужно подтвердить отправку своего контакта на следующем шаге!", (ok) => {
        if (/*ok &&*/ initData !== null) {
            console.log('Confirmed');
            makeRequest(initData.user.id)
                .then(data => {
                    console.log(data)
                    tg.requestContact((success, _) => {
                        if (success) {
                            console.log('Confirmed');
                            tg.showAlert("Заявка отправлена!")
                            tg.close()
                        } else {
                            console.log('Canceled');
                            if (initData !== null) {
                                cancelRequest(initData.user.id)
                                    .then(data => {
                                        console.log(data)
                                    })
                                    .catch(e => {
                                        console.log(e)
                                    })
                            }
                            setCounted(false)
                            tg.showAlert("Заявка отменена!")
                        }
                    })
                })
                .catch(e => {
                    console.log(e)
                    tg.showAlert("Не удалось отправить заявку, попробуйте заново!")
                })
        }
        //})
    }

    function currencyFromHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        let value = parseInt(e.target.value)
        if (!isNaN(value)) {
            setCurrencyFrom(value)
        } else {
            setCurrencyFrom(null)
        }
    }

    function currencyToHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        let value = parseInt(e.target.value)
        if (!isNaN(value)) {
            setCurrencyTo(value)
        } else {
            setCurrencyTo(null)
        }
    }

    function deliveryTypeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        let value = parseInt(e.target.value)
        if (!isNaN(value)) {
            setDeliveryType(value)
        } else {
            setDeliveryType(null)
        }
    }

    function bankHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        const value = e.target.value
        const banks = ["tinkoff", "sber", "raiffeisen", "sbp", "cash"]
        if (banks.includes(value)) {
            setBank(value)
        } else {
            setBank(null)
        }
    }

    function sumInputHandler(e: React.ChangeEvent<HTMLInputElement>) {
        let strValue = e.target.value
        let value = parseInt(e.target.value)
        if (!isNaN(value) || strValue !== "") {
            setSum(value)
        } else {
            setSum(0)
        }
    }

    return (
        <>
            {initData !== null && !isBlocked && initData !== undefined && (
                <VStack spacing={"10px"} alignItems="center" width={{base: "90vw", lg: "30vw"}} border="solid"
                        borderWidth={"1px"} borderColor={"rgba(183, 219, 0, 0.8)"} padding={"10px"}>
                    <Text fontFamily="RoadRadio" fontSize={{base: "24px", lg: "36px"}}
                          color="white">Калькулятор</Text>

                    <HStack width="100%">
                        <FormControl>
                            <FormLabel color={"white"} fontFamily="Raleway-light">Валюта, которую
                                отдаете</FormLabel>
                            <Select
                                variant="outline"
                                placeholder={"Выбрать"}
                                onChange={e => currencyFromHandler(e)}
                            >
                                <option className="myOption" value={RUB}>РУБЛИ</option>
                                <option className="myOption" value={THB}>БАТЫ</option>
                                <option className="myOption" value={USDT}>USDT</option>
                                <option className="myOption" value={USD}>ДОЛЛАРЫ</option>
                            </Select>
                        </FormControl>

                        <FormControl>
                            <FormLabel color={"white"} fontFamily="Raleway-light">Валюта, которую
                                получите</FormLabel>
                            <Select
                                variant="outline"
                                placeholder={"Выбрать"}
                                value={currencyTo || undefined}
                                onChange={e => currencyToHandler(e)}

                            >
                                {
                                    currencyToList?.map((cur, index) =>
                                        <option key={index} className="myOption"
                                                value={cur}>{currencyRusName(cur)}</option>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </HStack>

                    <FormControl>
                        <FormLabel color={"white"} fontFamily="Raleway-light">Способ выдачи</FormLabel>
                        <Select
                            variant="outline"
                            placeholder={"Выбрать"}
                            onChange={e => deliveryTypeHandler(e)}
                        >
                            {
                                deliveryTypes?.map((dt, index) =>
                                    <option key={index} className="myOption"
                                            value={dt}>{deliveryTypeName(dt)}</option>
                                )
                            }
                        </Select>
                    </FormControl>

                    {currencyFrom === RUB && <FormControl>
                        <FormLabel color={"white"} fontFamily="Raleway-light">Способ оплаты</FormLabel>
                        <Select
                            variant="outline"
                            placeholder={"Выбрать"}
                            onChange={e => bankHandler(e)}>
                            <option className="myOption" value="tinkoff">Тинькофф</option>
                            <option className="myOption" value="sber">СБЕР</option>
                            <option className="myOption" value="raiffeisen">Райффайзен</option>
                            <option className="myOption" value="sbp">СПБ</option>
                            <option className="myOption" value="cash">Наличка</option>
                        </Select>
                    </FormControl>}

                    {
                        currencyTo !== null && currencyFrom !== null &&
                        (<FormControl>
                            <FormLabel color={"white"} fontFamily="Raleway-light">Способ</FormLabel>
                            <Select
                                variant="outline"
                                placeholder={"Выбрать"}
                                fontSize={"16px"}
                                onChange={e => setFromTo(strToBoolean(e.target.value))}
                            >
                                <option className="myOption" value={"true"}>У меня есть {currencyFromName}, сколько
                                    получу
                                    в {currencyToName}</option>
                                <option className="myOption" value={"false"}>Мне нужны {currencyToName}, сколько
                                    заплатить
                                    в {currencyFromName}</option>
                            </Select>
                        </FormControl>)
                    }

                    <VStack width="100%">
                        <FormControl>
                            <FormLabel color={"white"} fontFamily="Raleway-light">Введите сумму
                                в {curName}</FormLabel>
                            <Input
                                ref={inputRef}
                                background={"#434343"}
                                borderRadius={0}
                                textColor={"white"}
                                fontFamily="Raleway-light"
                                fontSize={"18px"}
                                type={"number"}
                                _focusVisible={{borderColor: "rgba(183, 219, 0, 0.8)"}}
                                onKeyDown={event => handleKeyDown(event)}
                                onChange={e => sumInputHandler(e)}
                            />
                        </FormControl>

                        {/*{counted && <FormControl>
                            <FormLabel color={"white"} fontFamily="Raleway-light">Итого</FormLabel>
                            <Text
                                background={"#434343"}
                                borderRadius={0}
                                textColor={"white"}
                                fontFamily="Raleway-light"
                                fontSize={"20px"}
                                padding={"10px"}
                            >
                                {exchangeRequest?.bank && (
                                    <>
                                        Банк: <b>{bankName(exchangeRequest?.bank)}</b>
                                        <br/>
                                    </>
                                )}
                                {exchangeRequest?.typeOfDelivery !== undefined && (
                                    <>
                                        Способ выдачи: <b>{deliveryTypeName(exchangeRequest?.typeOfDelivery)}</b>
                                    </>
                                )}
                                <br/>
                                <br/>
                                {fromTo && exchangeRequest?.currencyFrom !== undefined && exchangeRequest?.currencyTo !== undefined ? (
                                    <>
                                        {exchangeData?.sumFrom} {currencyName(exchangeRequest.currencyFrom)} -&gt; {exchangeData?.sumTo} {currencyName(exchangeRequest.currencyTo)}
                                    </>
                                ) : exchangeRequest !== undefined && exchangeRequest?.currencyTo !== undefined ? (
                                        <>
                                            {exchangeData?.sumTo} {currencyName(exchangeRequest.currencyTo)} -&gt; {exchangeData?.sumFrom} {currencyName(exchangeRequest.currencyFrom)}
                                        </>
                                    ) :
                                    <>
                                    </>
                                }
                                <br/>
                                <br/>
                                {exchangeData?.course && (
                                    <>
                                        {exchangeData.course}
                                    </>
                                )}
                            </Text>
                        </FormControl>}*/}

                        {exchangeData?.filePath !== undefined && (
                            <Image src={`${baseUrl}/photo/${exchangeData?.filePath}`}/>
                        )}

                        <Button
                            background={countButtonColor}
                            borderRadius={0}
                            borderWidth={"1px"}
                            borderColor={"rgba(183, 219, 0, 0.8)"}
                            width={"100%"}
                            height={"40px"}
                            _hover={{md: {background: "rgba(183, 219, 0, 0.8)"}}}
                            textColor={"white"}
                            textAlign={"center"}
                            fontFamily="Raleway-light"
                            fontWeight={"light"}
                            fontSize={"20px"}
                            onTouchStart={_ => setCountButtonColor("rgba(183, 219, 0, 0.8)")}
                            onTouchEnd={_ => setCountButtonColor("#434343")}
                            onClick={_ => count()}
                        >
                            {
                                isLoading ? <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color="rgba(183, 219, 0, 0.8)"
                                    size='lg'
                                /> : counted ? "Пересчитать" : "Посчитать"
                            }
                        </Button>

                        {
                            counted && <Button
                                background={sendButtonColor}
                                borderRadius={0}
                                borderWidth={"1px"}
                                borderColor={"white"}
                                width={"100%"}
                                height={"40px"}
                                _hover={{md: {background: "#434343"}}}
                                textColor={"white"}
                                textAlign={"center"}
                                fontFamily="Raleway-light"
                                fontWeight={"light"}
                                fontSize={"20px"}
                                onTouchStart={_ => setSendButtonColor("#434343")}
                                onTouchEnd={_ => setSendButtonColor("rgba(183, 219, 0, 0.8)")}
                                onClick={_ => sendRequest()}
                            >
                                Отправить заявку
                            </Button>
                        }
                    </VStack>
                </VStack>
            )}
        </>
    );
}

export default Calculator;